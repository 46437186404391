<template>
  <div>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="series"
      :height="height" 
      :width="width"
    />
  </div>
</template>
  
  <script>
  import VueApexCharts from 'vue-apexcharts';
  
  export default {
    name: 'BarChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      height: {
        type: [String, Number],
        default() {
          return 400;
        },
      },
      width: {
        type: [String, Number],
        default() {
          return 500;
        },
      },    
   
  },
    data() {
      return {
        series: [
          {
            //name: 'Fuel Vehicle Cost',
            data: [10, 20, 15],
          },
        ],
        chartOptions: {
            chart: {
            //height: 100,
            type: 'line',
            width: '100%',
            height: '100%',
          
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          //text: 'Average High & Low Temperature',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar'],
          /*title: {
            text: 'Month'
            
          }*/
        },
        yaxis: {
          /*title: {
            text: 'Temperature'
          },*/
          show: false,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -15,
          offsetX: -5
        }
        
        },
  
      };
    },
  };
  </script>